<template>
  <div :class="{ 'bg-white': !isDesktop }">
    <router-link
      to="/settings"
      class="back-link py-5 pb-md-3 px-3 px-md-0"
    >
      <v-icon size="24px" color="#6170C5">
        navigate_before
      </v-icon>
      <div
        :class="['pl-4', 'line-h-23', 'px-md-0', isDesktop ? 'grey-text fz-14 font-weight-regular' : 'dark-text fz-20 font-weight-medium']"
      >
        Payments</div>
    </router-link>
    <h1 v-if="isDesktop" class="dark-text font-weight-bold fz-28 mb-4 px-4 px-md-0">{{ isNewPayment ? 'Add' : 'Edit' }} payment method</h1>
    <v-card :loading="loading" :style="{ 'min-height': loading ? '4px' : 'unset'} " class="overflow-hidden">
      <v-form v-if="!loading" v-model="valid" lazy-validation ref="creditCard">
        <v-col class="pa-4">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <label class="fz-14 mb-1 d-block">Card number</label>
              <v-text-field
                :value="cardNumber"
                :rules="cardRules"
                :maxlength="19"
                solo
                outlined
                label="Card number"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                type="card"
                dense
                hide-details
                @keypress="IsNumber($event)"
                @input="setCardNumber"
              ></v-text-field>
            </v-col>

            <v-col
              cols="8"
              md="3"
              class="pr-2"
            >
              <label class="fz-14 mb-1 d-block">Expiration date</label>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                ref="menu"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="expirationDate"
                    solo
                    outlined
                    dense
                    hide-details
                    label="Expiration date"
                    :style="{ height: isDesktop ? '36px' : '48px' }"
                    type="text"
                    class="pt-0"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expirationDatePicker"
                  :min="today"
                  type="month"
                  no-title
                  scrollable
                  ></v-date-picker>
                  <!-- @input="$refs.menu.save(form.expirationDate)" -->
              </v-menu>
            </v-col>

            <v-col
              cols="4"
              md="2"
              style="padding-right:17px"
            >
              <label class="fz-14 mb-1 d-block">CVV</label>
              <v-text-field
                v-model="form.cvc"
                :rules="cvvRules"
                :maxlength="3"
                solo
                outlined
                label="CVV"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                type="text"
                dense
                hide-details
                @keypress="IsNumber($event)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <label class="fz-14 mb-1 d-block">Country</label>
              <v-autocomplete
                v-model="country"
                :items="countryList"
                item-text="name"
                item-value="code"
                label="Country"
                :height="isDesktop ? '36px' : '48px'"
                menu-props="offsetY"
                solo
                outlined
                hide-details
              >
                <template v-slot:append>
                  <v-icon size="15px">unfold_more</v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <label class="fz-14 mb-1 d-block">Address</label>
              <v-text-field
                v-model="form.billing_address_1"
                :rules="addressRules"
                solo
                outlined
                label="Address"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                type="text"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <label class="fz-14 mb-1 d-block">City</label>
              <v-text-field
                v-model="form.city"
                :rules="cityRules"
                :maxlength="24"
                solo
                outlined
                label="City"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                type="text"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <label class="fz-14 mb-1 d-block">State</label>
              <v-select
                v-if="states.length"
                v-model="form.state"
                :items="states"
                item-text="name"
                item-value="value"
                label="State"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                menu-props="offsetY"
                solo
                outlined
                hide-details
              >
                <template v-slot:append>
                  <v-icon size="15px">unfold_more</v-icon>
                </template>
              </v-select>
              <v-text-field
                v-else
                v-model="form.state"
                solo
                outlined
                label="State"
                :height="isDesktop ? '36px' : '48px'"
                type="text"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              style="padding-right:17px"
            >
              <label class="fz-14 mb-1 d-block">ZIP code</label>
              <v-text-field
                v-model="form.zip_code"
                :rules="zipRules"
                :maxlength="5"
                solo
                outlined
                label="ZIP code"
                :style="{ height: isDesktop ? '36px' : '48px' }"
                type="text"
                dense
                hide-details
                @keypress="IsNumber($event)"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-col>
      </v-form>
      <footer-buttons
        v-if="!isDesktop"
        :disabled="isDisable"
        :ok="isNewPayment ? 'Create' : 'Save'"
        class="ma-4"
        @ok="addCard"
      />
      <vue-snotify />
    </v-card>
    <header-buttons v-if="isDesktop" :ok="isNewPayment ? 'Create' : 'Save'" @ok="addCard" :disabled="isDisable" :changed="changed"/>
  </div>
</template>

<script>
import HeaderButtons from '@/components/HeaderButtons'
import FooterButtons from '@/components/FooterButtons'

import { mapGetters, mapActions } from 'vuex'
import countryList from '@/imports/countryList'
import moment from 'moment'

export default {
  name: 'AddPaymentMethod',
  components: { HeaderButtons, FooterButtons },
  data: () => ({
    loading: false,
    countryList,
    form: {
      number: '',
      exp_month: 1,
      exp_year: 0,
      cvc: '',
      billing_address_1: '',
      city: '',
      state: '',
      zip_code: ''
    },
    changed: false,
    valid: false,
    menu: false,
    country: 'US',
    states: [],
    cardRules: [
      v => !!v || 'Card number is required',
      v => /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{16}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test((v.replace(/\s+/g, ''))) || 'Card number must be valid'
    ],
    dateRules: [
      v => !!v || 'Date is required',
      v => /^[0-9]+-[0-9]+$/.test(v) || 'Date must be valid'
    ],
    cvvRules: [
      v => !!v || 'CVV is required',
      v => (/^[0-9]+$/.test(v) && v.length <= 3) || 'CVV must be valid'
    ],
    addressRules: [
      v => !!v || 'Address is required'
    ],
    cityRules: [
      v => !!v || 'City is required'
    ],
    stateRules: [
      v => !!v || 'State is required'
    ],
    zipRules: [
      v => !!v || 'ZIP code is required'
    ]
  }),
  computed: {
    ...mapGetters({
      userData: 'currentUser',
      cardList: 'paymentCards'
    }),
    isNewPayment () {
      return this.$route.name === 'AddPaymentMethod'
    },
    isDisable () {
      return !this.valid || Object.values(this.form).some(e => !e) || this.form.number.length < 16
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    expirationDate: {
      get () {
        return `${this.form.exp_month < 10 ? `0${this.form.exp_month}` : this.form.exp_month}/${this.form.exp_year}`
      }
    },
    expirationDatePicker: {
      get () {
        const [m, y] = this.expirationDate.split('/')
        return `${y}-${m}`
      },
      set (val) {
        const [y, m] = val.split('-')
        this.form.exp_year = parseInt(y)
        this.form.exp_month = parseInt(m)
      }
    },
    cardNumber () {
      try {
        const cleaned = `${this.form.number}`
        const match = cleaned.match(/.{1,4}/g)
        return match.join(' ')
      } catch (err) {
        return this.form.number
      }
    },
    currentYear () {
      const dateobj = new Date()
      return dateobj.getFullYear()
    },
    currentMonth () {
      const dateobj = new Date()
      return parseInt(dateobj.getMonth()) + 1
    },
    today () {
      return moment(new Date()).format('YYYY-MM-DD')
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.changed = true
      }
    },
    country () {
      this.loadStates()
    }
  },
  created () {
    this.loadStates()
    this.form.exp_year = this.currentYear
    this.form.exp_month = this.currentMonth
    if (!this.isNewPayment && this.$route.params.id) this.loadPaymentData()
  },
  mounted () {
    if (!this.isNewPayment && !this.$route.params.id) {
      this.$snotify.error('Payment id is undefined')
      setTimeout(() => {
        this.$router.push({ name: 'Settings' })
      }, 2000)
    }
  },
  methods: {
    ...mapActions(['getStates', 'getPaymentMethods', 'postPaymentCard', 'patchPaymentCard']),
    async loadStates () {
      try {
        const { data } = await this.getStates({ country: this.country })
        if (!data || !data.payload) return
        this.states = data.payload.map(item => ({
          name: item,
          value: item
        }))
        this.form.state = ''
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadPaymentData () {
      try {
        this.loading = true
        await this.getPaymentMethods()
        const card = this.cardList.find(e => e.id === this.$route.params.id)
        if (!card) return
        this.form.exp_month = card.card.exp_month
        this.form.exp_year = card.card.exp_year
        this.form.billing_address_1 = card.billing_details.address.line1
        this.form.city = card.billing_details.address.city
        this.form.state = card.billing_details.address.state
        this.form.zip_code = card.billing_details.address.postal_code
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async addCard () {
      if (!this.$refs.creditCard.validate()) return
      this.$snotify.notifications = []
      try {
        const { data } = this.isNewPayment ? await this.postPaymentCard(this.form) : await this.patchPaymentCard({ id: this.$route.params.id, data: this.form })
        if (!data) return
        this.$snotify.success(`Payment method has ${this.isNewPayment ? 'added' : 'saved'}.`)
        this.form = {
          number: '',
          exp_month: this.currentMonth,
          exp_year: this.currentYear,
          cvc: '',
          billing_address_1: '',
          city: '',
          state: '',
          zip_code: ''
        }
        this.$refs.creditCard.resetValidation()
        setTimeout(() => {
          this.$router.push({ name: 'Settings' })
        }, 3000)
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    setCardNumber (e) {
      this.form.number = e.replace(/\D/g, '')
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  ::v-deep  .v-input__slot {
    border: 1px solid #DADDDF !important;
    box-shadow: none !important;
  }
}
</style>
