<template>
  <v-card
    class="ma-auto pa-10 overflow-hidden"
    max-width="660px"
  >
    <v-card-title class="pa-0 pb-8">
      <h2 class="font-weight-semi-bold fz-28 dark-text">Invitation</h2>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-form v-model="formValidate" lazy-validation>
        <v-row class="mt-0">
          <!-- <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Company Name</label>
            <v-text-field
              solo
              outlined
              label="Enter company Name"
              type="text"
              v-model="form.companyName"
              dense
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Email</label>
            <v-text-field
              solo
              outlined
              label="Email"
              type="email"
              v-model="form.email"
              :rules="emailRules"
              dense
            ></v-text-field>
          </v-col> -->

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Password</label>
            <v-text-field
              solo
              outlined
              label="Min 8 characters"
              type="password"
              v-model="form.password"
              :rules="passwordRules"
              dense
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Confirm password</label>
            <v-text-field
              solo
              outlined
              label="Enter password one more time"
              type="password"
              v-model="form.rePassword"
              :rules="passwordRules"
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          ></v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-end"
          >
            <v-btn
              class="violet-button"
              v-on:click="submit"
              height="44px"
            >Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <vue-snotify/>
  </v-card>
</template>

<script>

export default {
  name: 'Registration',
  data: () => ({
    loading: false,
    form: {
      // companyName: '',
      // email: '',
      password: '',
      rePassword: '',
      invitationUuid: ''
    },
    formValidate: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ]
  }),
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters',
        v => v.length <= 20 || 'Password must be less than 20 characters',
        v => /(?=.*[!@#$%^&*])/.test(v) || 'Password must contain at least one special character',
        v => /(?=.*[0-9])/.test(v) || 'Password must contain at least one numeric character',
        v => /(?=.*?[A-Z])/.test(v) || 'Password must contain at least one uppercase alphabetical character',
        v => /(?=.*?[a-z])/.test(v) || 'Password must contain at least one lowercase alphabetical character',
        v => v === this.form.password || 'Passwords does not match'
      ]
    },
    invitationUuid () {
      return this.$route.query.invitationUuid
    }
  },
  methods: {
    submit () {
      if (this.formValidate) {
        this.form.invitationUuid = this.invitationUuid

        // setTimeout(() => {
        const form = {
          password: this.form.password,
          rePassword: this.form.rePassword,
          invitationUuid: this.form.invitationUuid
        }
        this.$store.dispatch('acceptInvitation', {
          form
        }).then(async (res) => {
          this.$snotify.notifications = []
          await this.$snotify.success('Account has been created successfully')
          setTimeout(e => {
            this.$router.push({ name: 'Login' })
          }, 2000)
        })
          .catch((err) => {
            this.$snotify.error(err)
            this.loading = false
          })
          .finally(() => {
            this.loading = false
          })
        // }, 1500)
        // this.$store
        //   .dispatch('registration', {
        //     form: this.form
        //   }).then((res) => {
        //     this.$snotify.success('You are registered successfully')

        //     // auto login with registered data
        //   })
        //   .catch((err) => {
        //     this.$snotify.error(err)
        //     this.loading = false
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })
      } else {
        console.log('Invalid form!')
      }
    }
  }
}
</script>
